import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Contact from '../contact/contact'

import Header from "../header/header";
import Footer from "../footer/footer";
import "./layout.scss";
import "../../utils/normalize.css";

const HomeLayout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query HomeTitleQuery {
      site {
        siteMetadata {
          title
        }
      },
    }
  `);

  return (
    <>
    <Header headerClass="home" />
    <Contact />
    {children}
    <Footer siteTitle={data.site.siteMetadata.title} footerClass="home" />
    </>
  )
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  siteTitle: PropTypes.string
}

HomeLayout.defaultProps = {
  siteTitle: ``
}

export default HomeLayout;
