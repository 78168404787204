import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from 'gatsby-background-image'
import ContactForm from '../components/contact-form/ContactForm'

import HomeLayout from '../components/layout/home-layout'
import SEO from '../components/seo'
import "./home.scss"
import './contact.scss'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomeImgQuery {
      desktop: file(relativePath: { eq: "components/layout/img/background.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <HomeLayout>
      <SEO title="WRR Enterprises" description="" />
      <BackgroundImage
        Tag="main"
        className="home"
        fluid={imageData}
        backgroundColor={`#000`}
      >
        <div className="home-content">
          <div className="contact-form">
              <p>Contact WRR Enterprises to see what we can do to sort out your ERP data troubles.</p>
              <ContactForm />
          </div>
        </div>
      </BackgroundImage>
    </HomeLayout>
  )
}

export default IndexPage
